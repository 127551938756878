// Developer     : Magalí Perea
// Creation Date : 27-2-2024
// Version       : 1
// Description   : Este componente conserva todas las funciones y estados necesarios para el guardado del cliente con sus respectivas direcciones de entrega.

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container, Tooltip, Fab, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid"; ///me da un unico id para poner en el comprobante
import SaveIcon from "@mui/icons-material/Save";
import { UrlApi } from "../../../../../services/apirest";
import Notify from "../../../../../components/Notify";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DialogConfirmDir from "./DialogConfirmDir";
import GroupIcon from "@mui/icons-material/Group";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ReceiptIcon from "@mui/icons-material/Receipt";
//Componentes propios:
import AltaDomicilios from "./formularios/AltaDomicilios";
import AltaCliente from "../alta/formularios/AltaCliente";
import Impuestos from "../alta/formularios/Impuestos";

import TitleUploaded from "../../../../../components/TitleUploaded";

function TabPanel(props) {
  const [uuid, setUuid] = useState(uuidv4()); //Este UUID voy a usar para vincularme con el resto de las cosas que necesito.
  //Clientes:
  const [dataCliente, setDataCliente] = useState({
    razon_social: "",
    direccion_fiscal: "",
    telefono1: "",
    telefono2: "",
    telefono3: "",
    mails: "",
    cuit: null,
    id_categoria: "",
    // id_sucursal: "",
    id_localidad: "",
    id_comercial: "",
    id_segmento: "",
    id_subsegmento: "",
    id_condicionpago: "",
    sisven_id: null,
    situacion_iva: "",
    usuario: "",
    activo: 1,
    limite_credito: 0,
    observaciones: "",
    id_tipocliente: "",
    pesada_cliente: 0,
    id_provincia: "",
    nro_cliente: uuid,
    tipo_doc_afip: null,
    facturacion_especial: null,
    factura_consfinal: null,
    porc_perc_iva:1.75,
    porc_exc_perc_iva: null,
    vto_exc_perc_iva:null
  });
  const [tipo, setTipo] = useState(1); //tipo=1 (cuit) || tipo=0 (dni)

  //Domicilios de entrega:
  const [esDomEntrega, setEsDomEntrega] = useState(false);
  const [listaDom, setListaDom] = useState([
    {
      nro_dir: uuid,
      direccion_entrega: "",
      nombre_direccion: "",
      id_provincia: "",
      id_localidad: "",
      id_sucursal: "",
      contacto: "",
      telefono1: "",
      telefono2: "",
      telefono3: "",
      mails: "",
      latitud: "",
      longitud: "",
      usuario: localStorage.getItem("user"),
      activo: 1,
      verificada: 1,
      id_empresa: null,
      id_tipomoneda: 2
    },
  ]);

  //Estados para manejar visuales:
  const [activeTab, setActiveTab] = useState("cliente");
  const [notify, setNotify] = useState({
    open: false,
    severity: "",
    color: "",
    mensaje: "",
  });
  const icon = <WarningAmberIcon sx={{ fontSize: 40 }} />; //Icono que le paso al dialog
  const [disGuardar, setDisGuardar] = useState(true); //habilita o deshabilita el boton de guardado
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [datosFaltantes, setDatosFaltantes] = useState(); //Guarda los datos que faltan para que los formularios estén completos y se puedan guardar.

  // ---------------------FUNCIONES------------------ //

  //Añade un nuevo domicilio:
  const almacenarDomicilios = () => {
    const values = [...listaDom];
    values.push({
      nro_dir: uuid,
      direccion_entrega: "",
      nombre_direccion: "",
      id_provincia: "",
      id_localidad: "",
      id_sucursal: "",
      contacto: "",
      telefono1: "",
      telefono2: "",
      telefono3: "",
      mails: "",
      latitud: "",
      longitud: "",
      usuario: localStorage.getItem("user"),
      activo: 1,
      verificada: 1,
      id_producto: dataCliente.id_tipocliente === 1 ? 1 : null,
      id_empresa: "",
      id_tipomoneda: 2
    });
    setListaDom(values);
  };

  //Almacena los datos del domicilio que voy ingresando
  const updateItemsDom = (prop, valueOrEvent, index) => {
    const value =
      typeof valueOrEvent === "object"
        ? valueOrEvent.target.value
        : valueOrEvent;
    const old = listaDom[index];
    const updated = {
      ...old,
      [prop]: value === "" ? null : value,
    };
    const clone = [...listaDom];
    clone[index] = updated;
    setListaDom(clone);
  };

  //Actualiza las coordenadas de latitud y longitud
  const actualizarCoordenadas = (indice, lat, lng) => {
    // Crear una copia del array actual de direcciones
    const domicilios = [...listaDom];

    // Actualizar las coordenadas en el objeto JSON en el índice especificado
    domicilios[indice].latitud = lat;
    domicilios[indice].longitud = lng;

    // Actualizar el estado del array de direcciones con las nuevas coordenadas
    setListaDom(domicilios);
  };

  //Valida que existan determinados datos del cliente para que no de error al guardar.
  const validarCli = () => {
    console.log(dataCliente.situacion_iva)
    if (
     !!dataCliente.razon_social &&
      !!dataCliente.id_tipocliente &&
      !!dataCliente.situacion_iva &&
      !!dataCliente.id_condicionpago &&
      !!dataCliente.direccion_fiscal &&
      !!dataCliente.id_provincia &&
      !!dataCliente.id_localidad &&
      !!dataCliente.id_segmento &&
      !!dataCliente.id_subsegmento &&
      !!dataCliente.id_comercial &&
      !!dataCliente.id_categoria && ((dataCliente.situacion_iva===1 && dataCliente.porc_exc_perc_iva < 100 )|| dataCliente.situacion_iva!==1)
      // (dataCliente.situacion_iva===1 && dataCliente.porc_exc_perc_iva < 100)
    ) {

      setDisGuardar(false);
    } else {
      setDisGuardar(true);
    }
  };

  useEffect(() => {
    validarCli();
  }, [dataCliente]);



  // console.log(dataCliente)

  //Conforma el cuerpo de la solicitud. Es lo que voy a decirle a la api que debe guardar.
  const reqBody = {
    clienteData: {...dataCliente,
      porc_perc_iva:dataCliente.situacion_iva===1?(dataCliente.porc_perc_iva||null):null, 
      porc_exc_perc_iva:dataCliente.situacion_iva===1?(dataCliente.porc_exc_perc_iva||null):null,
      vto_exc_perc_iva: dataCliente.situacion_iva===1?(dataCliente.vto_exc_perc_iva||null):null,
      factura_consfinal: dataCliente.situacion_iva===1?null : dataCliente.factura_consfinal,
      facturacion_especial: dataCliente.situacion_iva===1?(dataCliente.facturacion_especial||null) : null,
     },
    //aca filtro para enviar solo las direcciones de entrega que contengan provincia, localidad y producto, que si no están, me rompen.
    dirData: listaDom.filter(
      (domicilio) =>
        !!domicilio.direccion_entrega &&
        !!domicilio.id_provincia &&
        !!domicilio.id_localidad &&
        !!domicilio.id_producto &&
        !!domicilio.id_empresa
    ),

  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClose = () => {
    props.alta(false);
    props.tabla(true);
  };

  // ---------------- FUNCIONES DE GUARDADO: -------------------- //

  //Para guardar, primero chequeo si tengo los datos necesarios en la dirección de entrega para que no se rompa la bd al momento del post. Si hay datos que faltan, se mostrarán en un diálogo. Si todo esta completo, se ejecuta la función "postCliDir", que es la función que se comunica con la API para guardar los datos y devolver una respuesta.
  const guardar = (e) => {
    e.preventDefault();
    if (
      !!listaDom[0].direccion_entrega ||
      !!listaDom[0].nombre_direccion ||
      !!listaDom[0].id_provincia ||
      !!listaDom[0].id_localidad ||
      !!listaDom[0].id_sucursal ||
      !!listaDom[0].id_empresa 

    ) {
      //Hay algo en el primer domicilio de entrega, entonces vamos a analizar todo lo que haya
      const falta = listaDom
        .map((domicilio, index) => {
          const datosFaltantes = [];
          if (!domicilio.id_provincia) datosFaltantes.push("Provincia");
          if (!domicilio.id_localidad) datosFaltantes.push("Localidad");
          if (!domicilio.id_sucursal) datosFaltantes.push("Sucursal");
          if (!domicilio.id_producto) datosFaltantes.push("Producto");
          if (!domicilio.direccion_entrega) datosFaltantes.push("Dirección de entrega");
          if (!domicilio.id_empresa) datosFaltantes.push("Empresa");
          return {
            data: domicilio,
            index: index,
            faltaDatos: datosFaltantes.length > 0,
            datosFaltantes: datosFaltantes,
          };
        })
        .filter((elemento) => elemento.faltaDatos);
      const elementosFaltantes = falta.map((elemento) => {
        const datosFaltantesObj = { index: elemento.index };
        elemento.datosFaltantes.forEach((propiedad) => {
          datosFaltantesObj[propiedad] = true;
        });
        return datosFaltantesObj;
      });
      setDatosFaltantes(elementosFaltantes);
      if (falta.length > 0) {
        setShowConfirmDialog(true);
      } else {
        postCliDir(e, reqBody, setNotify);
      }
    } else {
      postCliDir(e, reqBody, setNotify);
    }
  };

  async function postCliDir(e, reqBody, setNotify) {
    console.log("reqbody", reqBody);
    e.preventDefault();
    setDisGuardar(true);
    const response = await fetch(UrlApi + "/clientes/postclidir", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    });
    if (response.ok === false) {
      setNotify({
        open: true,
        severity: "error",
        color: "#FADBD8",
        mensaje: "Error al guardar",
      });
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotify({
            open: true,
            severity: "success",
            color: "#D4EFDF",
            mensaje: "Guardado",
          });
        } else if (json.sqlMessage) {
          setNotify({
            open: true,
            severity: "error",
            color: "#FADBD8",
            mensaje: "Error al guardar",
          });
        } else {
          setNotify({
            open: true,
            severity: "error",
            color: "#FADBD8",
            mensaje: "Error al guardar",
          });
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          setNotify({
            open: true,
            severity: "success",
            color: "#D4EFDF",
            mensaje: "Guardado",
          });
        } else {
          setNotify({
            open: true,
            severity: "error",
            color: "#FADBD8",
            mensaje: "Error al guardar",
          });
        }
      }
    }
  };

  return (
    <div>
      {notify.open ? (
        <Notify
          open={notify.open}
          color={notify.color}
          severity={notify.severity}
          mensaje={notify.mensaje}
          handleClose={() => handleClose()}
        />
      ) : null}

      <Container maxWidth="xl">
        <TitleUploaded
          title={"Nuevo Cliente"}
          handleClose={() => {
            props.alta(false);
            props.tabla(true);
          }}
        />
        <Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxHeight: "50px",
            }}
          >
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
              aria-label="basic tabs example"
              centered
              variant="fullWidth"
              sx={{
                maxHeight: "50px",
              }}
            >
              <Tab
                value="cliente"
                label="cliente"
                iconPosition="end"
                icon={<GroupIcon />}
                sx={{
                  marginTop: -1,
                  maxHeight: "50px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <Tab
                disabled ={dataCliente.situacion_iva !== 1 } //solo se habilita si el cliente nuevo es responsable inscripto.
                value="impuestos"
                label="Impuestos"
                {...a11yProps(1)}
                iconPosition="end"
                icon={<ReceiptIcon />}
                sx={{
                  marginTop: -1,
                  maxHeight: "50px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
              <Tab
                value="domicilios"
                label="Direcciones de entrega (opcional)"
                {...a11yProps(1)}
                iconPosition="end"
                icon={<AddLocationAltIcon />}
                sx={{
                  marginTop: -1,
                  maxHeight: "50px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Tabs>
            {activeTab === "cliente" ? (
              <>
                <AltaCliente
                  dataCliente={dataCliente}
                  setDataCliente={setDataCliente}
                  tipo={tipo}
                  setTipo={setTipo}
                  setListaDom={setListaDom}
                  listaDom={listaDom}
                  esDomEntrega={esDomEntrega}
                  setEsDomEntrega={setEsDomEntrega}
                  uuid={uuid}
                />
              </>
            ) : null}
            {activeTab  === "impuestos" ? (
              <>
                <Impuestos
                  dataCliente={dataCliente}
                  setDataCliente={setDataCliente}
                />
              </>
            ) : null}
            {activeTab === "domicilios" ? (
              <>
                <AltaDomicilios
                  listaDom={listaDom}
                  setListaDom={setListaDom}
                  almacenarDomicilios={almacenarDomicilios}
                  handleInputChange={updateItemsDom}
                  actualizarCoordenadas={actualizarCoordenadas}
                  tipoCliente={dataCliente.id_tipocliente}
                  uuid={uuid}
                />
              </>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            mb: 3,
          }}
        >
          <Tooltip title="Guardar">
            <Fab
              disabled={disGuardar}
              color="success"
              aria-label="add"
              onClick={guardar}
              sx={{ mr: 1 }}
            >
              <SaveIcon />
            </Fab>
          </Tooltip>
        </Box>
        {showConfirmDialog && (
          <DialogConfirmDir
            notify={{ open: false }}
            open={showConfirmDialog}
            setOpen={setShowConfirmDialog}
            color="red"
            icon={icon}
            title={"Datos Incompletos"}
            subtitle={
              "Algunos domicilios no cuentan con la información requerida: "
            }
            items={datosFaltantes}
            confirmText={"Por favor, complételos e intente nuevamente."}
          />
        )}
      </Container>
    </div>
  );
}

export default TabPanel;
