/*****Desarrollo
export const AuthApi = 'https://devauthapi.netizar.com/api/' //27017
//export const UrlApi = 'https://devapi.netizar.com/api' //27020
export const UrlApi = "http://localhost:27020/api";
export const MailApi = 'https://uservices.netizar.com' //puerto 3535
export const SmsApi = 'https://sms.netizar.com/api' // puerto 3537
export const TelemetriaApi = 'http://prod.netizar.com:3536/api'
export const titulonavbar = 'DevExt'
export const UrlApp = 'http://dev.netizar.com:3000/'
//export const UrlApi = 'https://devapi.netizar.com/api' //27020
// export const UrlApiEnvasado = "http://192.168.34.65:9000/api";
export const UrlApiEnvasado = "http://localhost:9000/api";
*/


export const AuthApi = "https://prodauthapi.netizar.com/api/";
export const UrlApi = "https://prodapi.netizar.com/api";
export const MailApi = "https://uservices.netizar.com"; //puerto 3535
export const SmsApi = "https://sms.netizar.com/api"; // puerto 3537
export const titulonavbar = "Producción"
export const UrlApp = "https://prod.netizar.com/"
export const UrlApiEnvasado = "http://localhost:9000/api";


/*
export const AuthApi = "http://190.210.128.78:27018/api/";
export const UrlApi = "http://190.210.128.78:27019/api";
export const MailApi = "http://190.210.128.78:3535"
export const SmsApi = "https://farma.netizar.com:3537/api"
export const TelemetriaApi = "http://prod.netizar.com:3536/api"
export const titulonavbar = "Producción"
export const UrlApp = "http://prod.netizar.com/"
*/

/*
export const AuthApi="http://192.168.34.242:27018/api/";
export const UrlApi="http://192.168.34.242:27019/api";
export const titulonavbar="Producción Local" 
*/
